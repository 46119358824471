<template>
    <ul id="messages" v-if="messages">
        <li v-for="msg in messages.slice()" :key="msg.id">{{ msg.message }}</li>
    </ul>
</template>

<script>
export default {
  name: 'Messages',
  computed: {
    messages () {
      return this.$store.state.messages
    }
  },
  updated () {
    // whenever data changes and the component re-renders, this is called.
    this.$nextTick(() => this.scrollToEnd())
  },
  methods: {
    scrollToEnd: function () {
      // scroll to the start of the last message
      this.$el.scrollTop = this.$el.lastElementChild.offsetTop
    }
  }
}
</script>
